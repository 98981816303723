import axios from "@/plugins/axios";
import qs from "qs";

export const getAllProducts = async (page = 1, pageSize = 500, cityId) => {
  const query = qs.stringify(
    {
      sort: ["id:asc"],
      pagination: {
        page,
        pageSize,
      },
      filters: {
        cities: {
          id: { $eq: cityId },
        },
      },
      populate: {
        image: "*",
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/products?${query}`);
  return response?.data;
};

export const getProductsByFilters = async (
  sortBy,
  pagination,
  excludeId,
  cityId
) => {
  if (!cityId) {
    cityId =
      localStorage.key("selectedCity") !== "undefined"
        ? JSON.parse(localStorage.getItem("selectedCity"))?.id
        : 2;
  }

  let queryFilters = { filters: {} };
  if (excludeId) {
    queryFilters.filters["id"] = {
      $ne: excludeId,
    };
  }

  let sort = {};

  if (sortBy) {
    sort = { sort: [`${sortBy.field}:${sortBy.sortType}`] };
    // sort: [`${sortBy.field}:${sortBy.sortType}`],
  } else {
    sort = { sort: ["id:asc"] };
  }

  const query = qs.stringify(
    {
      pagination: {
        page: pagination?.page || 1,
        pageSize: pagination?.pageSize || 1500,
      },
      populate: "*",
      ...sort,
      ...queryFilters,
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response = await axios.get(`/products?${query}`);

  return response?.data;
};

export const getProductById = async (id) => {
  const query = qs.stringify(
    {
      populate: {
        cities: {
          populate: "*",
        },
        image: "*",
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/products/${id}?${query}`);
  return response?.data?.data;
};

export const getShopProducts = async (id, pagination, sortBy) => {
  let sort = {};

  if (sortBy) {
    sort = { sort: [`${sortBy.field}:${sortBy.sortType}`] };
    // sort: [`${sortBy.field}:${sortBy.sortType}`],
  } else {
    sort = { sort: ["id:asc"] };
  }
  const query = qs.stringify({
    populate: {
      image: "*",
      localizations: "*",
    },
    ...sort,
    filters: {
      shops: {
        id: { $eq: id },
      },
    },
    pagination: {
      page: pagination?.page || 1,
      pageSize: pagination?.pageSize || 20,
    },
  });
  const response = await axios.get(`/products?${query}`);
  return response?.data;
};

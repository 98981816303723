import { getAllCities } from "@/api";

export const mutationTypes = {
  setSelectedCity: "mutation/setSelectedCity",

  loadAllCitiesStart: "mutation/loadAllCitiesStart",
  loadAllCitiesSuccess: "mutation/loadAllCitiesStart",
  loadAllCitiesFailure: "mutation/loadAllCitiesFailure",
};
export const actionTypes = {
  changeSelectedCity: "action/changeSelectedCity",
  loadAllCities: "action/loadAllCities",
};

export const cityModule = {
  namespaced: true,
  state: () => ({
    data: null,
    selectedCity: null,
    isLoading: false,
    error: null,
  }),

  mutations: {
    [mutationTypes.setSelectedCity](state, payload) {
      state.selectedCity = payload;
    },
    [mutationTypes.loadAllCitiesStart](state) {
      state.isLoading = true;
    },
    [mutationTypes.loadAllCitiesSuccess](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.data = payload;
    },
    [mutationTypes.loadAllCitiesFailure](state, payload) {
      state.isLoading = false;
      state.error = payload;
    },
  },
  actions: {
    [actionTypes.loadAllCities]({ commit }) {
      commit(mutationTypes.loadAllCitiesStart);
      return new Promise((resolve) => {
        getAllCities()
          .then((cities) => {
            const localCity = localStorage.getItem("selectedCity")
              ? JSON.parse(localStorage.getItem("selectedCity"))
              : cities.find((c) => c.attributes.name === "Алматы");

            commit(mutationTypes.loadAllCitiesSuccess, cities);
            commit(
              mutationTypes.setSelectedCity,
              cities.find((c) => c.id === localCity.id)
            );
            localStorage.setItem(
              "selectedCity",
              JSON.stringify(cities.find((c) => c.id === localCity.id))
            );
            localStorage.setItem("cities", JSON.stringify(cities));
            resolve(cities);
          })
          .catch((e) => {
            commit(mutationTypes.loadAllCitiesFailure, e);
          });
      });
    },
    [actionTypes.changeSelectedCity]({ commit }, city) {
      if (city) {
        commit(mutationTypes.setSelectedCity, city);
        localStorage.setItem("selectedCity", JSON.stringify(city));
      }
    },
  },
  gettters: {},
};

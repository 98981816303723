import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n from "@/plugins/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:lang(ru|kz)?",
    component: {
      template: "<router-view />",
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "about",
        name: "About",
        component: () => import("@/views/about/AboutParty"),
      },
      {
        path: "heating-systems",
        name: "HeatingSystems",
        component: () => import("@/views/heating-systems/HeatingSystems"),
      },
      {
        path: "stocks",
        name: "stocks",
        component: () => import("@/views/stocks/PageStocks"),
      },
      {
        path: "stocks/:id",
        name: "stockItem",
        props: true,
        component: () => import("@/views/stocks/FullStocks"),
      },
      {
        path: "stocks/products/:id",
        name: "stockOnlyDiscount",
        props: true,
        component: () => import("@/views/stocks/PageStocksProducts"),
      },
      {
        path: "products/PageCatalog",
        name: "Catalog",
        props: true,
        component: () => import("@/views/products/PageCatalog"),
      },
      {
        path: "products/:id",
        name: "fullProduct",
        props: true,
        component: () => import("@/views/products/FullProduct"),
      },
      {
        path: "catalog",
        name: "catalog",
        component: () => import("@/views/products/PageCatalog"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (from.name === "catalog" && to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }

    if (from.name === "catalog") {
      return false;
    }

    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;
  console.log(
    "LANG IN ROUTE",
    language,
    "FROM LANG",
    from.params.lang,
    "TO LANG",
    to.params.lang
  );
  if (!language) {
    language = "ru";
    to.params.lang = language;
    console.log("NOT LANG", to);
    // router.push({ name: to.name });
    // next({ name: to.name, params: { lang: language }, query: to.query });
  }

  // set the current language for vuex-i18n. note that translation data
  // for the language might need to be loaded first
  i18n.locale = language;
  next();
});

export default router;

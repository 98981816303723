<template>
  <div class="home">
    <main>
      <home-banner-slider />
      <discount-list />
      <app-popular-categories />
      <app-stocks />
      <app-join-club />
      <app-useful-articles />

      <!-- 
      <weekly-products-list :page-size="5" />

      <slider-production />

      <brands-list />

      <shops-map
        v-if="shopsList"
        :is-show-shop-types="true"
        :shops-list="shopsList"
      />
      <div v-else class="loader-mini__container">
        <div class="loader-mini"></div>
      </div> -->
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getPromos } from "@/api";
import { getShopsByFilters } from "@/api/shop";
import { getGooglePlay, getAppStore, getAppGallery } from "@/api/links";

export default {
  name: "Home",
  components: {
    HomeBannerSlider: () => import("@/components/sliders/HomeBannerSlider.vue"),
    DiscountList: () => import("@/components/discount/DiscountList.vue"),
    AppStocks: () => import("@/components/stocks/AppStocks.vue"),
    AppUsefulArticles: () =>
      import("@/components/useful-articles/AppUsefulArticles.vue"),
    AppJoinClub: () => import("@/components/join-club/AppJoinClub.vue"),
    AppPopularCategories: () =>
      import("@/components/popular-categories/AppPopularCategories.vue"),

    // WeeklyProductsList: () =>
    //   import("@/components/products/WeeklyProductsList.vue"),
    // BrandsList: () => import("@/components/brands/BrandsList.vue"),
    // ShopsMap: () => import("@/components/maps/ShopsMap.vue"),
    // SliderProduction: () => import("@/components/sliders/SliderProduction.vue"),
  },
  data() {
    return {
      googlePlayLink: null,
      appStoreLink: null,
      appGalleryLink: null,

      shopsList: null,
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    swiper() {
      return this.$refs?.swiperBannersRef;
    },
    cityId() {
      return this.$i18n.locale === "kz"
        ? this.selectedCity?.attributes.localizations?.data[0].id
        : this.selectedCity.id;
    },
  },
  watch: {
    selectedCity: {
      handler() {
        getShopsByFilters(this.selectedCity.id).then((shops) => {
          this.shopsList = shops;
        });
      },
    },
  },
  created() {
    if (!this.shopsList && this.selectedCity?.id) {
      getShopsByFilters(this.selectedCity?.id).then((shops) => {
        this.shopsList = shops;
      });
    }

    getGooglePlay().then((googlePlay) => {
      this.googlePlayLink = googlePlay.data.attributes.link;
    });
    getAppStore().then((appStore) => {
      this.appStoreLink = appStore.data.attributes.link;
    });
    getAppGallery().then((appGallery) => {
      this.appGalleryLink = appGallery.data.attributes.link;
    });
  },

  methods: {
    getClubLink(link) {
      window.location.href = link;
    },
    nextStocksPage() {
      this.stocks.page++;
      getPromos(
        { type: "current" },
        {
          page: this.stocks.page,
          pageSize: this.stocks.pageSize,
        }
      ).then((stocks) => {
        this.stocks.data = [...this.stocks.data, ...stocks.data];
        if (this.stocks.page === stocks.meta.pagination.pageCount) {
          this.stocks.isLastPage = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.loader__container {
  margin-top: 150px;
}
@media (max-width: 1250px) {
  .loader__container {
    margin-top: 100px;
  }
}
@media (max-width: 600px) {
  .loader__container {
    margin-top: 60px;
  }
}
</style>

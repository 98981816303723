<template>
  <div>
    <app-menu />
    <cities-list />
    <app-modal-registration v-if="isAuthActive"/>
    <app-header :fixed="isFixed" />
    <div class="default-wrapper" style="min-height: 50vh">
      <slot />
    </div>
    <app-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import { actionTypes as actionTypesCity } from "@/store/modules/city";
import { actionTypes as actionTypesCatalog } from "@/store/modules/catalog";

export default {
  name: "DefaultLayout",
  components: {
    AppMenu: () => import("@/components/common/AppMenu.vue"),
    AppHeader: () => import("@/components/common/AppHeader.vue"),
    AppFooter: () => import("@/components/common/AppFooter.vue"),
    CitiesList: () => import("@/components/common/CitiesList.vue"),
    AppModalRegistration: () => import("@/components/modal/AppModalRegistration.vue"),
  },
  data() {
    return {
      isFixed: false,
    };
  },
  computed: {
    ...mapState(["isOpenSelectCity", 'isAuthActive']),
    ...mapState("cityModule", { cities: "data", selectedCity: "selectedCity" }),
  },
  watch: {
    isOpenSelectCity: {
      handler() {
        if (this.isOpenSelectCity) {
          document.querySelector("body").classList.add("no-scroll");
        } else {
          document.querySelector("body").classList.remove("no-scroll");
        }
      },
    },
  },

  mounted() {
    if (localStorage.getItem("selectedCity")) {
      this.changeSelectedCity(JSON.parse(localStorage.getItem("selectedCity")));
    }
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    }
    this.$router.onReady(() => {
      console.log(
        "On READY",
        this.$route,
        this.$route.params.lang,
        this.$i18n.locale
      );

      if (this.$route.params.lang !== this.$i18n.locale) {
        console.log("SISS", this.$route);
        this.$router.replace({
          query: { city_id: this.selectedCity?.id || 2 },
        });
      }
    });
  },
  methods: {
    ...mapActions({ changeLang: actionTypes.changeCurrentLanguage }),
    ...mapActions("cityModule", {
      changeSelectedCity: actionTypesCity.changeSelectedCity,
    }),
    ...mapActions("catalogModule", {
      clearFilters: actionTypesCatalog.clearFilters,
    }),
    changeIsFixed() {
      this.isFixed = true;
    },
  },
};
</script>

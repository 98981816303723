export const mutationTypes = {
  putSearchTerm: "mutation/putSearchTerm",
  putIsSearchStart: "mutation/putIsSearchStart",
  putSearchEntity: "mutation/putSearchEntity",
};
export const actionTypes = {
  getSearchTerm: "action/getSearchTerm",
  getIsSearchStart: "action/getIsSearchStart",
  getSearchEntity: "action/getSearchEntity",
};

export const searchModule = {
  namespaced: true,
  state: () => ({
    term: null,
    isSearchStart: null,
    entity: null,
  }),

  mutations: {
    [mutationTypes.putSearchTerm](state, payload) {
      state.term = payload;
    },
    [mutationTypes.putIsSearchStart](state, payload) {
      state.isSearchStart = payload;
    },
    [mutationTypes.putSearchEntity](state, payload) {
      state.entity = payload;
    },
  },
  actions: {
    [actionTypes.getSearchTerm]({ commit }, searchTerm) {
      commit(mutationTypes.putSearchTerm, searchTerm);
    },
    [actionTypes.getIsSearchStart]({ commit }, isSearchStart) {
      commit(mutationTypes.putIsSearchStart, isSearchStart);
    },
    [actionTypes.getSearchEntity]({ commit }, searchEntity) {
      commit(mutationTypes.putSearchEntity, searchEntity);
    },
  },
};

import Vue from "vue";
import Vuex from "vuex";
import { cityModule } from "./modules/city";
import { newsModule } from "./modules/news";
import { stockModule } from "./modules/stock";
import { catalogModule } from "./modules/catalog";
import { searchModule } from "./modules/search";
import { vacancyModule } from "./modules/vacancy";
Vue.use(Vuex);

export const mutationTypes = {
  setIsOpenMenu: "mutation/setIsOpenMenu",
  setIsOpenSelectCity: "mutation/setIsOpenSelectCity",

  setCurrentLanguage: "mutation/setCurrentLanguage",
  
  // isAuthActive modal
  setIsAuthActive: "mutation/setIsAuthActive",
};
export const actionTypes = {
  changeIsOpenMenu: "action/changeIsOpenMenu",
  changeIsOpenSelectCity: "action/changeIsOpenSelectCity",
  changeIsCloseMenu: "action/changeIsCloseMenu",

  changeCurrentLanguage: "action/changeCurrentLanguage",

  // isAuthActive modal
  changeIsAuthActive: "action/changeIsAuthActive",

};

export default new Vuex.Store({
  state: {
    isOpenMenu: false,
    isOpenSelectCity: false,
    currentLanguage: null,
    isAuthActive: false,
  },
  mutations: {
    [mutationTypes.setIsOpenMenu](state) {
      state.isOpenMenu = !state.isOpenMenu;
      if (state.isOpenMenu) {
        document.querySelector("body").classList.add("no-scroll");
      } else {
        document.querySelector("body").classList.remove("no-scroll");
      }
    },
    [mutationTypes.setIsOpenSelectCity](state) {
      state.isOpenSelectCity = !state.isOpenSelectCity;
    },
    [mutationTypes.setCurrentLanguage](state, payload) {
      state.currentLanguage = payload;
    },
    

    [mutationTypes.setIsAuthActive](state) {
      state.isAuthActive = !state.isAuthActive;
      // console.log(state.isAuthActive)
    },
  },
  actions: {
    [actionTypes.changeIsOpenMenu]({ commit }) {
      commit(mutationTypes.setIsOpenMenu);
    },
    [actionTypes.changeIsOpenSelectCity]({ commit }) {
      commit(mutationTypes.setIsOpenSelectCity);
    },
    [actionTypes.changeIsCloseMenu]({ commit }) {
      commit(mutationTypes.setIsOpenMenu);
    },
    [actionTypes.changeCurrentLanguage]({ commit }, lang) {
      commit(mutationTypes.setCurrentLanguage, lang);
    },


    [actionTypes.changeIsAuthActive]({ commit }) {
      commit(mutationTypes.setIsAuthActive);
    },
  },
  modules: {
    cityModule,
    newsModule,
    stockModule,
    catalogModule,
    searchModule,
    vacancyModule,
  },
  // plugins: [vueLocalStorage.plugin],
});
